import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 500 500"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      d: "M300 417.2H142.5c-11.3 0-20.2-3.2-27.3-9.8S104 392.3 103.3 383c-.9-12.5-.9-24.9.1-36.9.6-7 2-14.1 4.2-21.2 1.8-5.6 3.8-10.3 6.1-14.3 5.7-9.9 11.4-17.3 17.9-23.2 8.9-8.1 17.7-14 26.9-17.7 9.9-4.1 19.9-6.4 29.9-6.9 4.1-.2 8.9-.4 13.6-.4 2.1 0 5.3.3 8.3 1.8 6.9 3.4 14.6 5.2 21.3 6.5 7.1 1.4 14.8 1.9 23.4 1.6 8.5-.4 16.8-1.8 24.7-4.3 1.5-.5 3-1.1 4.7-1.7 2.9-1.1 5.8-2.3 9.1-3 4.9-1.1 9.8-1 13.9-.8 5.1.3 10.4.9 16.8 1.9 3.6.6 7.2 1.5 10.7 2.9 4.1 1.6 9.5 3.8 14.7 6.6 3.6 1.9 7.3 4.3 11.8 7.6 3.8 2.8 7 5.6 9.8 8.5 6.7 7 12.5 15.4 17.5 25.8 3.7 7.6 6.1 15.8 7.4 25.1 1.6 11.9 1.5 23.8 1.3 35.3v2.5c-.2 12.8-4.8 22.8-13.8 29.7-6.8 5.2-14.1 8-21.9 8.3-5.9.2-12.8.4-19.8.4-14.1.1-28 .1-41.9.1zm-50.8-15h92.5c6.8 0 13.6-.2 19.3-.4q6.9-.15 13.2-5.1c5.3-4.1 7.8-9.8 8-18v-2.5c.2-11.4.4-22.2-1.1-33-1.1-7.7-3-14.4-6-20.6-4.3-8.9-9.2-16.1-14.8-21.9-2.2-2.3-4.9-4.6-8-6.9-3.8-2.8-6.9-4.9-9.8-6.4-4.6-2.4-9.5-4.4-13.2-5.9-2.5-1-5.1-1.7-7.6-2.1-5.9-.9-10.7-1.5-15.3-1.8-3.1-.2-6.7-.3-9.8.4-2.2.5-4.5 1.4-6.9 2.3-1.8.7-3.6 1.4-5.6 2-9.2 2.9-18.8 4.6-28.6 5-9.9.4-18.7-.2-27-1.9-7.6-1.5-16.5-3.6-24.9-7.8-.1-.1-.8-.3-1.8-.3-4.4 0-8.9.2-12.8.4-8.2.4-16.6 2.4-24.9 5.8-7.5 3.1-14.8 8-22.5 14.9-5.3 4.8-10.1 11-15 19.6-1.8 3.1-3.3 6.8-4.8 11.3-1.9 6-3.1 12-3.6 17.8-.9 11.2-.9 22.9-.1 34.6.4 5.6 2.8 10.4 7.2 14.5 4.2 4 9.7 5.8 17.1 5.8h79.8zm.6-145.1c-23.4 0-45.3-9.1-61.7-25.7-16.5-16.7-25.4-38.9-25.1-62.7.6-47.8 38.8-85.6 86.8-85.9 23.3-.1 45.2 8.8 61.7 25.3 16.6 16.5 25.6 38.7 25.6 62.3.1 47.7-38.9 86.5-86.9 86.7zm.6-159.3h-.5c-39.8.3-71.4 31.5-71.9 71.1-.2 19.7 7.1 38.2 20.8 52 13.6 13.7 31.7 21.3 51.1 21.3h.2c39.8-.1 72.1-32.3 72-71.7v-.1c.1-19.6-7.4-38-21.1-51.7-13.7-13.5-31.6-20.9-50.6-20.9z"
    }, null, -1)
  ])))
}
export default { render: render }